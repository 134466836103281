function injectScript(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.append(script);
    });
}
export function monacoLoader() {
    return injectScript('monaco-editor/min/vs/loader.js').then(() => {
        return new Promise((resolve) => {
            window.require.config({
                paths: { vs: 'monaco-editor/min/vs' },
            });
            window.require(['vs/editor/editor.main'], resolve);
        });
    });
}
