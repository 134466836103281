import { addons, types } from '@storybook/addons';
import { AddonPanel } from '@storybook/components';
import React from 'react';
import { addonId, panelId, paramId } from './constants';
import { createStore } from './createStore';
import Editor from './Editor/Editor';
const store = createStore();
export function register() {
    addons.register(addonId, (api) => {
        addons.addPanel(panelId, {
            title: 'Live code editor',
            type: types.PANEL,
            paramKey: paramId,
            render({ active, key }) {
                var _a, _b, _c;
                const storyId = ((_c = (_b = (_a = api.getCurrentStoryData()) === null || _a === void 0 ? void 0 : _a.parameters) === null || _b === void 0 ? void 0 : _b.liveCodeEditor) === null || _c === void 0 ? void 0 : _c.id) || '';
                if (!active || !storyId) {
                    return null;
                }
                const storyState = store.getValue(storyId);
                return (React.createElement(AddonPanel, { active: true, key: key },
                    React.createElement(Editor, { ...storyState, onInput: (newCode) => {
                            store.setValue(storyId, { ...storyState, code: newCode });
                        }, value: storyState.code, parentSize: "100%" })));
            },
        });
    });
}
