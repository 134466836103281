function newStore(initialValue) {
    const callbacks = new Set();
    let value = initialValue;
    return {
        onChange(callback) {
            callbacks.add(callback);
            return () => {
                callbacks.delete(callback);
            };
        },
        getValue: () => value,
        setValue(newValue) {
            value = newValue;
            callbacks.forEach((callback) => {
                try {
                    callback(newValue);
                }
                catch (error) {
                    console.error(error);
                }
            });
        },
    };
}
function newKeyStore() {
    const stores = {};
    return {
        onChange: (key, callback) => (stores[key] || (stores[key] = newStore())).onChange(callback),
        getValue: (key) => (stores[key] || (stores[key] = newStore())).getValue(),
        setValue: (key, newValue) => (stores[key] || (stores[key] = newStore())).setValue(newValue),
    };
}
export function createStore() {
    var _a;
    return ((_a = window.top)._addon_code_editor_store || (_a._addon_code_editor_store = newKeyStore()));
}
