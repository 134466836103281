import * as React from 'react';
import { getMonacoOverflowContainer } from './getMonacoOverflowContainer';
import { monacoLoader } from './monacoLoader';
import { reactTypesLoader } from './reactTypesLoader';
import { getMonacoSetup } from './setupMonaco';
let monacoPromise;
function loadMonacoEditor() {
    const monacoSetup = getMonacoSetup();
    window.MonacoEnvironment = monacoSetup.monacoEnvironment;
    return (monacoPromise || (monacoPromise = Promise.all([monacoLoader(), reactTypesLoader()]).then(([monaco, reactTypes]) => {
        var _a;
        monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
            jsx: 1,
        });
        monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
            noSemanticValidation: true,
            noSyntaxValidation: false,
        });
        if (reactTypes) {
            monaco.languages.typescript.typescriptDefaults.addExtraLib(reactTypes, 'file:///node_modules/react/index.d.ts');
        }
        (_a = monacoSetup.onMonacoLoad) === null || _a === void 0 ? void 0 : _a.call(monacoSetup, monaco);
        return monaco;
    })));
}
let fileCount = 1;
function createEditor(monaco, code, container) {
    const uri = monaco.Uri.parse(`file:///index${fileCount++}.tsx`);
    return monaco.editor.create(container, {
        automaticLayout: true,
        fixedOverflowWidgets: true,
        model: monaco.editor.createModel(code, 'typescript', uri),
        overflowWidgetsDomNode: getMonacoOverflowContainer('monacoOverflowContainer'),
        tabSize: 2,
    });
}
export default function Editor(props) {
    const stateRef = React.useRef({ onInput: props.onInput }).current;
    const [_, forceUpdate] = React.useReducer((n) => n + 1, 0);
    let resolveContainer = () => { };
    React.useState(() => {
        const containerPromise = new Promise((resolve) => {
            resolveContainer = resolve;
        });
        Promise.all([containerPromise, loadMonacoEditor()]).then(([editorContainer, monaco]) => {
            stateRef.monaco = monaco;
            stateRef.editor = createEditor(monaco, props.value, editorContainer);
            stateRef.editor.onDidChangeModelContent(() => {
                var _a;
                const currentValue = (_a = stateRef.editor) === null || _a === void 0 ? void 0 : _a.getValue();
                if (typeof currentValue === 'string') {
                    stateRef.onInput(currentValue);
                }
            });
            forceUpdate();
        });
    });
    React.useLayoutEffect(() => {
        stateRef.onInput = props.onInput;
    }, [props.onInput]);
    React.useEffect(() => {
        if (stateRef.editor && stateRef.editor.getValue() !== props.value) {
            stateRef.editor.setValue(props.value);
        }
    }, [props.value]);
    React.useEffect(() => {
        var _a;
        if (stateRef.monaco && stateRef.editor) {
            (_a = props.modifyEditor) === null || _a === void 0 ? void 0 : _a.call(props, stateRef.monaco, stateRef.editor);
        }
    }, [stateRef.monaco, props.modifyEditor]);
    React.useEffect(() => {
        return () => {
            var _a;
            (_a = stateRef.editor) === null || _a === void 0 ? void 0 : _a.dispose();
            stateRef.editor = undefined;
        };
    }, []);
    return (React.createElement("div", { ref: (container) => {
            if (props.parentSize) {
                const parent = container === null || container === void 0 ? void 0 : container.parentElement;
                if (parent) {
                    parent.style.height = props.parentSize;
                }
            }
            stateRef.editorContainer = container || undefined;
            resolveContainer(container);
        }, style: { height: '100%' } }));
}
